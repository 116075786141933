<template>
	<!-- container -->
	<div>
		<!-- boxWhite -->
		<div class="boxWhite">
			<div class="inner">
				<!-- buildList -->
				<div class="buildList">
					<ul>
						<li v-for="(item, idx) in data" :key="idx">
							<a :href="'/building/' + item.id">
								<div class="imgArea">
									<img :src="item.imageId ? '/file/' + item.imageId : '/resources/images/@img01.png'" alt="" />
									<p v-if="item.isShare" class="label v1">공유</p>
									<p v-else class="label v2">MY</p>
								</div>
								<span>{{ item.name }}</span>
							</a>
						</li>
						<li>
							<a href="/building/regist" class="btnAdd">
								<div class="imgArea"><img src="/resources/images/ico-addline.svg" alt="" /></div>
								<span>관리건물 추가</span>
							</a>
						</li>
					</ul>
				</div>
				<!--// buildList -->
				<a @click="onBuildingTip()" class="btnInfo" style="top: -12px; right: 24px;">빌딩 등록 안내 TIP</a>
			</div>
		</div>
		<!--// boxWhite -->
		<!-- contents -->
		<div class="contents">
			<!-- memoList -->
			<div class="memoList">
				<ul v-if="memo.total > 0">
					<li v-for="(memo, index) in memoList" :key="index">
						<a @click="onMemo(memo.id)">
							<!-- location -->
							<div class="location">
								<span>{{ memo.buildingName }}</span>
								<span v-if="memo.floorId != null">{{ memo.floorName }}</span>
								<span v-if="memo.roomId != null">{{ memo.roomName }}</span>
								<span v-if="memo.objectPinId != null">{{ memo.objectName }}</span>
								<span v-if="memo.locations && memo.locations.length > 1" class="locationCount"
									>외 {{ memo.locations.length - 1 }}개</span
								>
							</div>
							<!--// location -->
							<!-- memo -->
							<div class="memo">
								<div class="user">
									<div class="imgArea">
										<p><img :src="`/file/${memo.creatorImageId}`" alt="" v-if="memo.creatorImageId" /></p>
									</div>
									<p>{{ memo.creatorName }}</p>
								</div>
								<div class="user" v-if="memo.projectName != null">
									<span> <strong>[프로젝트]</strong> </span>
									<span class="title">&nbsp;{{ memo.projectName }}</span>
									<span class="interval">
										&nbsp;({{ memo.projectBeginDate | dateFormatYear }}~{{ memo.projectEndDate | dateFormatYear }})
									</span>
								</div>
								<div class="user" v-if="memo.schedule.name">
									<span>
										<strong>[스케줄]</strong>
									</span>
									<span class="title">&nbsp;{{ memo.schedule.name }}</span>
									<span class="interval"
										>&nbsp;({{ setIntervalStr(memo.schedule.intervalType) }} {{ setDateFormat(memo.schedule) }})</span
									>
								</div>
								<p class="date">
									<span>{{ memo.createDate | dateFormatYear }}</span>
									<span>{{ memo.createDate | dateFormatMonth }}</span>
								</p>
								<p>
									<span>
										<pre v-if="memo.content" style="font-family: 'Noto Sans KR', sans-serif;">{{
											enterContent(memo.content).replace(/ /g, '&nbsp;')
										}}</pre>
									</span>
								</p>
							</div>
							<!--// memo -->
						</a>
						<!-- imgList -->
						<div class="devImgList">
							<div class="inBox">
								<p v-for="(memoFile, index) in memo.memoFiles" :key="index">
									<img :src="`/file/${memoFile.fileId}`" alt="" @click="onOpenImage(memoFile.fileId, memo.memoFiles)" />
								</p>
							</div>
						</div>
						<!--// imgList -->
						<!-- viewInfo -->
						<div class="viewInfo">
							<a class="btnComment">{{ memo.commentCount }}</a>
							<a class="btnViews">{{ memo.views }}</a>
						</div>
						<!--// viewInfo -->
					</li>
				</ul>
				<ul v-else>
					<h3>최신글</h3>
					<!-- noData -->
					<div class="noData">
						<p>등록된 최신글이 없습니다.</p>
					</div>
				</ul>
			</div>
			<!--// memoList -->
		</div>
		<!--// contents -->
		<PopUpImage ref="popUpImage"></PopUpImage>
	</div>
	<!--// container -->
</template>
<script>
import { showSpinner, hideSpinner, dateFormatYear, dateFormatMonth, setIntervalStr } from '@/utils/cmm';
import { showMenu } from '@/utils/cmm';
import building from '@/api/building';
import memo from '@/api/memo';
import PopUpImage from '../popup/popUpImage';

export default {
	components: { PopUpImage },
	data() {
		return {
			data: [],
			memoList: [],
			memo: {
				offset: 0,
				limit: 10,
				total: '',
			},
			isAtTheBottom: false,
		};
	},
	filters: {
		dateFormatYear,
		dateFormatMonth,
	},
	created() {
		showMenu();
		this.reload();
	},
	mounted() {
		this.$EventBus.$on('reload', () => {
			this.memoList = [];
			this.memo.offset = 0;
			this.reload();
		});
		this.$EventBus.$on('paging', () => {
			if (this.memo.total <= this.memo.offset) return false;
			this.onGetMemo();
		});
	},
	beforeDestroy() {
		this.$EventBus.$off('paging');
		this.$EventBus.$off('reload');
	},
	methods: {
		async reload() {
			try {
				showSpinner();
				let res = await building.get();
				this.data = res.data;
				await this.onGetMemo();
			} catch (ex) {
				await this.$alert(`건물 목록 조회 실패했습니다.\r\n${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		async onGetMemo() {
			let params = {
				offset: this.memo.offset,
				limit: this.memo.limit,
			};
			try {
				let res = await memo.get(params);
				this.memo.total = res.total;
				if (this.memo.total > 0) {
					res.data.forEach(p => this.memoList.push(p));
					this.memo.offset += this.memo.limit;
				}
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
		},
		onMemo(id) {
			this.$router.push({ name: 'memoDetail', params: { id: id, reqUrl: 'building' } });
		},
		onBuildingTip() {
			this.$router.push({ name: 'buildingTip' });
			// this.$router.push({ name: 'info' });
		},
		enterContent(content) {
			let count = content.match(/\n/g);
			if (count != null && count.length > 4) {
				let tmp = '';
				for (let i = 0; i < 5; i++) {
					tmp += content.split('\n')[i] + '\n';
				}
				content = tmp.substring(0, tmp.length - 1);
			}
			return content;
		},
		onOpenImage(imageId, memoFiles) {
			this.$refs.popUpImage.onOpenImage(imageId, memoFiles);
		},
		setIntervalStr(intervalType) {
			return setIntervalStr(intervalType);
		},
		setDateFormat(schedule) {
			let date = schedule.beginDate;
			switch (schedule.intervalType) {
				case 'Y':
					date = this.$moment(date).format('M월 D일');
					break;
				case 'M':
					date = this.$moment(date).format('D일');
					break;
				case 'W':
					switch (this.$moment(date).day()) {
						case 0:
							date = '일요일';
							break;
						case 1:
							date = '월요일';
							break;
						case 2:
							date = '화요일';
							break;
						case 3:
							date = '수요일';
							break;
						case 4:
							date = '목요일';
							break;
						case 5:
							date = '금요일';
							break;
						case 6:
							date = '토요일';
							break;
					}
					break;
				case 'D':
					date = this.$moment(date).format('YYYY월 M월 D일');
					break;
			}
			return date;
		},
	},
};
</script>

<style scoped>
.locationCount {
	padding-left: 5px;
	background: none;
}
</style>
